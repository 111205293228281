import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [imageUrl, setImageUrl] = useState('');
  const [imageId, setImageId] = useState('');

  // Function to construct the public image URL
  const constructImageUrl = (id) => {
    return `https://imagedelivery.net/mtHCoUz-ibmpfbSrguQF2Q/${id}/public`;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('imageId');
    if (id) {
      setImageId(id);
      const publicImageUrl = constructImageUrl(id);
      setImageUrl(publicImageUrl); // Set the public image URL
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ display: 'flex', width: '100%', height: '80vh' }}>
          <div style={{ flex: '0 0 80%', backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {imageUrl ? (
              <img src={imageUrl} alt="Fetched Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            ) : (
              <p style={{ textAlign: 'center' }}>No image uploaded. Please upload an image to preview.</p>
            )}
          </div>
          <div style={{ flex: '0 0 20%', backgroundColor: '#e0e0e0', padding: '10px' }}>
            <p style={{ textAlign: 'center' }}>Editor Options Placeholder</p>
            {/* You can add your editor options/components here */}
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
